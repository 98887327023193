import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
  MenuItem,
  Snackbar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import ApiLoader from '../../components/ApiLoader/ApiLoader'; // Import the ApiLoader component
import { toast, ToastContainer } from "react-toastify"; // Import Toast functions
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

const PlaceOrder = ({ customerId, advisorId }) => {
  const [products, setProducts] = useState([{ productId: "", quantity: 1, price: 0 }]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discount, setDiscount] = useState(0); // Initialize discount as a flat amount (not percentage)
  const [productOptions, setProductOptions] = useState([]);
  const [error, setError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state for products
  const [submitting, setSubmitting] = useState(false); // Loading state for submitting order

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true); // Set loading to true while fetching
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/operational-admin/products`
        );
        setProductOptions(response.data);
      } catch (error) {
        console.error("Failed to fetch products:", error);
        toast.error("Failed to fetch products."); // Show toast for fetch failure
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };
    
    fetchProducts();
  }, []);

  const handleProductChange = (index, field, value) => {
    const newProducts = [...products];
    if (field === "productId") {
      const selectedProduct = productOptions.find((p) => p._id === value);
      newProducts[index].productId = value;
      newProducts[index].price = selectedProduct ? Number(selectedProduct.price) : 0;
    } else {
      newProducts[index][field] = value;
    }

    // Set quantity to 1 if it's 0 or less
    if (field === "quantity" && value <= 0) {
      newProducts[index].quantity = 1;
    }

    setProducts(newProducts);
    calculateTotalAmount(newProducts, discount);
  };

  const addProduct = () => {
    setProducts([...products, { productId: "", quantity: 1, price: 0 }]);
  };

  const deleteProduct = (index) => {
    const newProducts = products.filter((_, i) => i !== index);
    setProducts(newProducts);
    calculateTotalAmount(newProducts, discount);
  };

  const calculateTotalAmount = (products, discount) => {
    const validDiscount = Number(discount) || 0; // Ensure discount is a number

    const subtotal = products.reduce((acc, product) => {
      const quantity = Number(product.quantity) || 0;
      const amount = Number(product.price) || 0;
      return acc + amount * quantity;
    }, 0);

    const total = subtotal - validDiscount; // Apply flat discount to subtotal

    setTotalAmount(total > 0 ? total : 0); // Ensure total is not negative
  };

  const handleDiscountChange = (value) => {
    const validDiscount = value < 0 ? 0 : Number(value) || 0; // Ensure discount is a non-negative number
    setDiscount(validDiscount);
    calculateTotalAmount(products, validDiscount); // Recalculate total amount with the new discount
  };

  const handleSubmit = async () => {
    // Check for empty product IDs
    const hasEmptyProduct = products.some(
      (product) => product.productId.trim() === ""
    );
    if (hasEmptyProduct) {
      setError("Please select all products.");
      setSnackbarOpen(true);
      toast.error("Please select all products."); // Show toast for empty fields
      return; // Prevent order placement if any product ID is empty
    }

    if (products.length === 0) {
      console.error("No products to place order");
      return; // Prevent order placement if no products
    }

    setSubmitting(true); // Indicate submitting has started
    try {
      // Prepare the products array to include the full details
      const orderProducts = products.map((product) => ({
        productId: product.productId,
        quantity: product.quantity,
        amount: product.price, // Include price for each product
      }));

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/advisory/place-order`,
        {
          customerId,
          advisorId,
          products: orderProducts,
          discount,
          totalAmount,
        }
      );
      console.log("Order placed:", response.data);
      toast.success("Order placed successfully!"); // Show success toast
      navigate("/advisory-dashboard/order-success"); // Navigate to an order success page
    } catch (error) {
      console.error("Error placing order:", error);
      toast.error("Error placing order. Please try again."); // Show error toast
    } finally {
      setSubmitting(false); // Indicate submitting has ended
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Show loader while fetching product options
  if (loading) {
    return <ApiLoader />;
  }

  return (
    <Box className="container mt-4">
            <ToastContainer />
            <Typography variant="h4" sx={{ color: '#6C584C' }} className="text-center mb-4">
                Place Order
            </Typography>
            {products.map((product, index) => (
                <Box key={index} className="row mb-3 align-items-center">
                    <div className="col-md-4">
                        <TextField
                            select
                            label="Product"
                            value={product.productId}
                            onChange={(e) => handleProductChange(index, "productId", e.target.value)}
                            fullWidth
                            required
                            variant="outlined"
                        >
                            {productOptions.map((option) => (
                                <MenuItem key={option._id} value={option._id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className="col-md-2">
                        <TextField
                            label="Quantity"
                            type="number"
                            value={product.quantity}
                            onChange={(e) => handleProductChange(index, "quantity", Number(e.target.value) || 1)}
                            fullWidth
                            required
                            variant="outlined"
                        />
                    </div>
                    <div className="col-md-3">
                        <TextField
                            label="Price"
                            type="number"
                            value={product.price}
                            fullWidth
                            variant="outlined"
                            disabled
                        />
                    </div>
                    <div className="col-md-1">
                        <IconButton onClick={() => deleteProduct(index)} color="error" aria-label="delete">
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </Box>
            ))}

            <Button
                variant="contained"
                sx={{
                    backgroundColor: '#6C584C', // Changed button color
                    borderRadius: 20,
                    "&:hover": { backgroundColor: "#DDE5B6", color: 'black' }, // Set hover color to #DDE5B6 and text color to black
                    color: 'white',
                    padding: '10px 20px',
                    fontWeight: 'bold',
                }}
                className="mb-3"
                onClick={addProduct}
            >
                Add Another Product
            </Button>

            <TextField
                label="Discount"
                type="number"
                value={discount} // Allow user input
                onChange={(e) => handleDiscountChange(e.target.value)}
                fullWidth
                variant="outlined"
                className="mb-3"
            />
            <Typography variant="h6" className="mb-4" sx={{ color: 'red' }}>
                Total Amount: ₹ {totalAmount.toFixed(2)} {/* Format total amount */}
            </Typography>
            <Button
                variant="contained"
                sx={{
                    backgroundColor: '#6C584C', // Changed button color
                    borderRadius: 20,
                    "&:hover": { backgroundColor: "#DDE5B6", color: 'black' }, // Set hover color to #DDE5B6 and text color to black
                    color: 'white',
                    padding: '10px 20px',
                    fontWeight: 'bold',
                }}
                fullWidth
                className="mb-4"
                onClick={handleSubmit}
                disabled={submitting} // Disable button while submitting
            >
                {submitting ? "Placing Order..." : "Place Order"} {/* Change button label based on loading state */}
            </Button>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={error}
            />
        </Box>
  );
};

export default PlaceOrder;