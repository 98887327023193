import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import axios from "axios";
import Sidebar from "../../components/SideNavbar/Sidebar";
import {
  Group,
  ShoppingCart,
  PendingActions,
  CheckCircle,
  Cancel,
  AttachMoney,
} from '@mui/icons-material'; // Import MUI icons

const OperationalAdminDashboard = () => {
  const [admin, setAdmin] = useState(null);
  const [advisoryCount, setAdvisoryCount] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [confirmCount, setConfirmCount] = useState(0);
  const [cancelCount, setCancelCount] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [pendingRevenue, setPendingRevenue] = useState(0);
  const [confirmRevenue, setConfirmRevenue] = useState(0);
  const [canceledRevenue, setCanceledRevenue] = useState(0);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/operational-admin/dashboard`,
          { withCredentials: true,headers: {
            "ngrok-skip-browser-warning": "true"  // Add this header
          } }
          
        );
        setAdmin(response.data);
      } catch (error) {
        setMessage(error.response?.data?.message || "An error occurred");
      }
    };

    const fetchCounts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/operational-admin/counts`,
          { withCredentials: true },
          {
            headers: {
              "ngrok-skip-browser-warning": "true"  // Add this header
            }
          }
        );
        setAdvisoryCount(response.data.advisoryCount);
        setProductCount(response.data.productCount);
      } catch (error) {
        setMessage("Failed to fetch counts.");
      }
    };

    const fetchOrderStatusCounts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/advisory/orders/status-count`, {
            withCredentials: true,
            headers: {
              'ngrok-skip-browser-warning': 'true'
            }
          }
        );
        // Handle response here...
      } catch (error) {
        console.error('Error fetching order status counts:', error);
      }
    };
    

    const fetchRevenueDataFromOrders = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/advisory/orders`,{
          headers: {
            "ngrok-skip-browser-warning": "true"  // Add this header
          }
        });
        const revenueData = calculateRevenue(response.data);
        setTotalRevenue(revenueData.totalRevenue);
        setPendingRevenue(revenueData.pendingRevenue);
        setConfirmRevenue(revenueData.confirmRevenue);
        setCanceledRevenue(revenueData.canceledRevenue);
      } catch (error) {
        setMessage("Failed to fetch order data for revenue calculations.");
      }
    };

    fetchAdminData();
    fetchCounts();
    fetchOrderStatusCounts();
    fetchRevenueDataFromOrders(); // New call to fetch revenue data
  }, []);

  // Calculate the revenue based on orders
  const calculateRevenue = (orders) => {
    const totalRevenue = orders.reduce((acc, order) => acc + order.totalAmount, 0);
    const pendingRevenue = orders
      .filter(order => order.orderStatus === "Pending")
      .reduce((acc, order) => acc + order.totalAmount, 0);
    const confirmRevenue = orders
      .filter(order => order.orderStatus === "Confirm")
      .reduce((acc, order) => acc + order.totalAmount, 0);
    const canceledRevenue = orders
      .filter(order => order.orderStatus === "Cancel")
      .reduce((acc, order) => acc + order.totalAmount, 0);
  
    return { totalRevenue, pendingRevenue, confirmRevenue, canceledRevenue };
  };

  if (message) {
    return <Typography color="error">{message}</Typography>;
  }

  return (
    <Sidebar>
      <Container maxWidth="lg" className="mt-5 p-4 bg-light rounded shadow-lg">
    <Box className="text-center mb-5">
        <Typography
            variant="h3"
            className="mb-4"  // Removed text-primary class if it's conflicting
            style={{ 
                fontFamily: "Roboto, sans-serif", 
                color: "#6C584C" // Set the color to #6C584C
            }}
        >
            Operational Admin Dashboard
        </Typography>
          {admin ? (
            <>
              <Typography variant="h5" className="text-success mb-3">
                Welcome, <strong>{admin.name}</strong>!
              </Typography>
              <Typography variant="body1" className="text-muted mb-4">
                <strong>Email:</strong> {admin.email}
              </Typography>

              {/* Existing Card Section */}
              <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    elevation={3}
                    className="shadow-sm text-center"
                    style={{ backgroundColor: "#3A6B3E" }}
                  >
                    <CardContent>
                      <Group style={{ fontSize: '40px', color: '#FFFFFF' }} />
                      <Typography variant="h5" style={{ color: "#FFFFFF" }}>
                        Number of Advisories
                      </Typography>
                      <Typography variant="h4" style={{ color: "#FFFFFF" }}>
                        {advisoryCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    elevation={3}
                    className="shadow-sm text-center"
                    style={{ backgroundColor: "#BBCD79" }}
                  >
                    <CardContent>
                      <ShoppingCart style={{ fontSize: '40px', color: '#004d00' }} />
                      <Typography variant="h5" style={{ color: "#004d00" }}>
                        Number of Products
                      </Typography>
                      <Typography variant="h4" style={{ color: "#004d00" }}>
                        {productCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Order Status Counts */}
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    elevation={3}
                    className="shadow-sm text-center"
                    style={{ backgroundColor: "#f1c40f" }}
                  >
                    <CardContent>
                      <PendingActions style={{ fontSize: '40px', color: '#FFFFFF' }} />
                      <Typography variant="h5" style={{ color: "#FFFFFF" }}>
                        Pending Orders
                      </Typography>
                      <Typography variant="h4" style={{ color: "#FFFFFF" }}>
                        {pendingCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    elevation={3}
                    className="shadow-sm text-center"
                    style={{ backgroundColor: "#2ecc71" }}
                  >
                    <CardContent>
                      <CheckCircle style={{ fontSize: '40px', color: '#FFFFFF' }} />
                      <Typography variant="h5" style={{ color: "#FFFFFF" }}>
                        Confirmed Orders
                      </Typography>
                      <Typography variant="h4" style={{ color: "#FFFFFF" }}>
                        {confirmCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    elevation={3}
                    className="shadow-sm text-center"
                    style={{ backgroundColor: "#e74c3c" }}
                  >
                    <CardContent>
                      <Cancel style={{ fontSize: '40px', color: '#FFFFFF' }} />
                      <Typography variant="h5" style={{ color: "#FFFFFF" }}>
                        Canceled Orders
                      </Typography>
                      <Typography variant="h4" style={{ color: "#FFFFFF" }}>
                        {cancelCount}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              {/* New Revenue Cards with Icons */}
              <Grid container spacing={4} justifyContent="center" className="mt-5">
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    elevation={3}
                    className="shadow-sm text-center"
                    style={{ backgroundColor: "#F39C12" }} // You can set your colors
                  >
                    <CardContent>
                      <AttachMoney style={{ fontSize: '40px', color: '#FFFFFF' }} />
                      <Typography variant="h5" style={{ color: "#FFFFFF" }}>
                        Total Revenue
                      </Typography>
                      <Typography variant="h4" style={{ color: "#FFFFFF" }}>
                      ₹ {totalRevenue.toFixed(2)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    elevation={3}
                    className="shadow-sm text-center"
                    style={{ backgroundColor: "#2980B9" }} // You can set your colors
                  >
                    <CardContent>
                      <PendingActions style={{ fontSize: '40px', color: '#FFFFFF' }} />
                      <Typography variant="h5" style={{ color: "#FFFFFF" }}>
                        Pending Revenue
                      </Typography>
                      <Typography variant="h4" style={{ color: "#FFFFFF" }}>
                      ₹ {pendingRevenue.toFixed(2)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    elevation={3}
                    className="shadow-sm text-center"
                    style={{ backgroundColor: "#27AE60" }} // You can set your colors
                  >
                    <CardContent>
                      <CheckCircle style={{ fontSize: '40px', color: '#FFFFFF' }} />
                      <Typography variant="h5" style={{ color: "#FFFFFF" }}>
                        Confirmed Revenue
                      </Typography>
                      <Typography variant="h4" style={{ color: "#FFFFFF" }}>
                      ₹ {confirmRevenue.toFixed(2)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    elevation={3}
                    className="shadow-sm text-center"
                    style={{ backgroundColor: "#C0392B" }} // You can set your colors
                  >
                    <CardContent>
                      <Cancel style={{ fontSize: '40px', color: '#FFFFFF' }} />
                      <Typography variant="h5" style={{ color: "#FFFFFF" }}>
                        Canceled Revenue
                      </Typography>
                      <Typography variant="h4" style={{ color: "#FFFFFF" }}>
                      ₹ {canceledRevenue.toFixed(2)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          ) : (
            <Typography variant="body1" className="text-muted">
              Loading...
            </Typography>
          )}
        </Box>
      </Container>
    </Sidebar>
  );
};

export default OperationalAdminDashboard;