import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Alert,
    Select,
    MenuItem,
    FormControl,
    Box,
    Typography,
    TableContainer,
    Button,
    Menu,
} from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import Sidebar from '../../components/SideNavbar/Sidebar';
import ApiLoader from '../../components/ApiLoader/ApiLoader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RevenueSummary from '../../components/RevenueSummary/RevenueSummary';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const OperationalAdminSeeOrders = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filterStatus, setFilterStatus] = useState('');
    const [updating, setUpdating] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedDateOrders, setSelectedDateOrders] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/advisory/orders`);
                if (Array.isArray(response.data)) {
                    setOrders(response.data);
                } else {
                    setError("Expected an array but received: " + JSON.stringify(response.data));
                }
            } catch (err) {
                setError(err.response ? err.response.data.message : "An error occurred while fetching orders.");
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    const handleStatusChange = async (orderId, newStatus) => {
        setUpdating(true);
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/advisory/orders/${orderId}/status`, { status: newStatus });
            setOrders(prevOrders => prevOrders.map(order => order._id === orderId ? { ...order, orderStatus: newStatus } : order));
            toast.success("Order status updated successfully!");
        } catch (error) {
            console.error("Failed to update order status:", error);
            setError("Failed to update order status");
            toast.error("Error updating order status");
        } finally {
            setUpdating(false);
        }
    };

    const handleFilterChange = (event) => {
        setFilterStatus(event.target.value);
    };

    const filteredOrders = Array.isArray(orders)
        ? orders.filter(order => !filterStatus || order.orderStatus === filterStatus)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        : [];

    const calculateRevenue = (orders) => {
        const totalRevenue = orders.reduce((acc, order) => acc + order.totalAmount, 0);
        const pendingRevenue = orders.filter(order => order.orderStatus === "Pending").reduce((acc, order) => acc + order.totalAmount, 0);
        const confirmRevenue = orders.filter(order => order.orderStatus === "Confirm").reduce((acc, order) => acc + order.totalAmount, 0);
        const canceledRevenue = orders.filter(order => order.orderStatus === "Cancel").reduce((acc, order) => acc + order.totalAmount, 0);
        return { totalRevenue, pendingRevenue, confirmRevenue, canceledRevenue };
    };

    const handleDateClick = (arg) => {
        const dateString = arg.dateStr;
        setSelectedDate(dateString);
    
        const filteredOrdersByDate = orders.filter(order => {
            const orderDate = new Date(order.createdAt);
            return (
                orderDate.getFullYear() === new Date(dateString).getFullYear() &&
                orderDate.getMonth() === new Date(dateString).getMonth() &&
                orderDate.getDate() === new Date(dateString).getDate()
            );
        });
    
        setSelectedDateOrders(filteredOrdersByDate);
    
        if (filteredOrdersByDate.length > 0) {
            toast.info(`Found ${filteredOrdersByDate.length} orders on ${new Date(dateString).toLocaleDateString()}`);
            setShowCalendar(false); // Hide the calendar when orders are found
        } else {
            toast.warning(`No orders found on ${new Date(dateString).toLocaleDateString()}`);
        }
    };

    const exportToExcel = () => {
        const excelData = filteredOrders.map(order => ({
            'Order Number': order._id,
            'Created At': new Date(order.createdAt).toLocaleString(),
            'Advisor Name': order.advisorId?.name || 'N/A',
            'Mobile Number': order.customerId?.mobileNumber || 'N/A',
            'Farmer Alt. Number': order.customerId?.alternativeNumber || 'N/A',
            'Farmer Name': order.customerId?.name || 'N/A',
            'Village': order.customerId?.village || 'N/A',
            'Taluka': order.customerId?.taluka || 'N/A',
            'District': order.customerId?.district || 'N/A',
            'Pincode': order.customerId?.pincode || 'N/A',
            'Nearby Location': order.customerId?.nearbyLocation || 'N/A',
            'Product Names': order.products.map(product => product.productId?.name || 'N/A').join(', '),
            'Total Quantity': order.products.reduce((acc, product) => acc + product.quantity, 0),
            'Final Amount': order.totalAmount,
            'Order Status': order.orderStatus,
        }));

        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");
        XLSX.writeFile(workbook, `AllOrders.xlsx`);
    };

    const csvData = filteredOrders.map(order => ({
        'Order Number': order._id,
        'Created At': new Date(order.createdAt).toLocaleString(),
        'Advisor Name': order.advisorId?.name || 'N/A',
        'Mobile Number': order.customerId?.mobileNumber || 'N/A',
        'Farmer Alt. Number': order.customerId?.alternativeNumber || 'N/A',
        'Farmer Name': order.customerId?.name || 'N/A',
        'Village': order.customerId?.village || 'N/A',
        'Taluka': order.customerId?.taluka || 'N/A',
        'District': order.customerId?.district || 'N/A',
        'Pincode': order.customerId?.pincode || 'N/A',
        'Nearby Location': order.customerId?.nearbyLocation || 'N/A',
        'Product Names': order.products.map(product => product.productId?.name || 'N/A').join(', '),
        'Total Quantity': order.products.reduce((acc, product) => acc + product.quantity, 0),
        'Final Amount': order.totalAmount,
        'Order Status': order.orderStatus,
    }));

    const exportToPDF = () => {
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'pt',
            format: 'letter',
            putOnlyUsedFonts: true,
            floatPrecision: 16 
        });

        const rows = filteredOrders.map(order => ({
            orderNumber: order._id,
            createdAt: new Date(order.createdAt).toLocaleString(),
            advisorName: order.advisorId?.name || 'N/A',
            mobileNumber: order.customerId?.mobileNumber || 'N/A',
            farmerAltNumber: order.customerId?.alternativeNumber || 'N/A',
            farmerName: order.customerId?.name || 'N/A',
            village: order.customerId?.village || 'N/A',
            taluka: order.customerId?.taluka || 'N/A',
            district: order.customerId?.district || 'N/A',
            pincode: order.customerId?.pincode || 'N/A',
            nearbyLocation: order.customerId?.nearbyLocation || 'N/A',
            productNames: order.products.map(product => product.productId?.name || 'N/A').join(', '),
            totalQuantity: order.products.reduce((acc, product) => acc + product.quantity, 0),
            finalAmount: order.totalAmount,
            orderStatus: order.orderStatus,
        }));

        const tableColumnNames = [
            'Order Number', 'Created At', 'Advisor Name', 'Mobile Number',
            'Farmer Alt. Number', 'Farmer Name', 'Village', 'Taluka', 
            'District', 'Pincode', 'Nearby Location', 'Product Names', 
            'Total Quantity', 'Final Amount', 'Order Status'
        ];

        doc.autoTable({
            head: [tableColumnNames],
            body: rows.map(row => [
                row.orderNumber,
                row.createdAt,
                row.advisorName,
                row.mobileNumber,
                row.farmerAltNumber,
                row.farmerName,
                row.village,
                row.taluka,
                row.district,
                row.pincode,
                row.nearbyLocation,
                row.productNames,
                row.totalQuantity,
                row.finalAmount,
                row.orderStatus
            ]),
            styles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                fontStyle: 'normal',
                overflow: 'linebreak',
                lineWidth: 0.2,
                lineColor: [0, 0, 0]
            },
            headStyles: {
                fillColor: [200, 200, 200],
                textColor: [0, 0, 0],
                fontStyle: 'bold'
            },
            margin: { top: 20, left: 20, right: 20, bottom: 20 },
            theme: 'grid'
        });

        doc.save('AllOrders.pdf');
    };

    const handleExportClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Handle loading and error states
    if (loading) {
        return (
            <Sidebar>
                <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                    <ApiLoader />
                </Box>
            </Sidebar>
        );
    }

    if (error) {
        return (
            <Sidebar>
                <Box m={3}>
                    <Alert severity="error">Error: {error}</Alert>
                </Box>
            </Sidebar>
        );
    }

    const { totalRevenue, pendingRevenue, confirmRevenue, canceledRevenue } = calculateRevenue(filteredOrders);

    return (
        <Sidebar>
            <ToastContainer />
            <Box sx={{ padding: 3, height: '100vh', overflow: 'hidden' }}>
                <Typography variant="h4" gutterBottom>
                    All Orders
                </Typography>

                <RevenueSummary 
                    totalRevenue={totalRevenue}
                    pendingRevenue={pendingRevenue}
                    confirmRevenue={confirmRevenue}
                    canceledRevenue={canceledRevenue} 
                />

                <FormControl sx={{ margin: '16px', minWidth: 200 }}>
                    <Select value={filterStatus} onChange={handleFilterChange}>
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="Pending">Pending</MenuItem>
                        <MenuItem value="Confirm">Confirm</MenuItem>
                        <MenuItem value="Cancel">Cancel</MenuItem>
                    </Select>
                </FormControl>

                <Box display="flex" sx={{ alignItems: 'center', mb: 2 }}>
                    <Button
                        variant="contained"
                        onClick={() => setShowCalendar(prev => !prev)}
                        sx={{ marginRight: 2 }}
                    >
                        {showCalendar ? 'Hide Calendar' : 'Show Calendar'}
                    </Button>

                    <Button
                        variant="contained"
                        onClick={handleExportClick}
                    >
                        Export
                    </Button>
                </Box>

                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem onClick={exportToExcel}>Export to Excel</MenuItem>
                    <CSVLink data={csvData} filename={"AllOrders.csv"} style={{ textDecoration: 'none' }}>
                        <MenuItem onClick={handleClose}>Export to CSV</MenuItem>
                    </CSVLink>
                    <MenuItem onClick={exportToPDF}>Export to PDF</MenuItem>
                </Menu>

                {showCalendar && (
                    <Box
                        className="calendar-container"
                        sx={{
                            mb: 4,
                            display: 'flex',
                            justifyContent: 'flex-start', // Align to the left
                            overflow: 'hidden',
                        }}
                    >
                        <Box
                            sx={{
                                width: '40%',
                                maxWidth: '450px', // Reduced width
                                height: '350px', // Decreased height
                                border: '1px solid #ddd',
                                borderRadius: '5px',
                                boxShadow: 2,
                                backgroundColor: 'white', // Set background color for clarity
                                overflow: 'hidden' // Prevent overflow of calendar
                            }}
                        >
                            <FullCalendar
                                plugins={[dayGridPlugin, interactionPlugin]}
                                events={[]} // Ensuring no events are passed, so dates will be blank
                                dateClick={handleDateClick}
                            />
                        </Box>
                    </Box>
                )}

                <div style={{ overflow: 'auto', height: '80%' }}>
                    <TableContainer
                        component={Paper}
                        sx={{
                            maxHeight: 'inherit',
                            overflowY: 'auto',
                            overflowX: 'auto'
                        }}
                    >
                        <Table sx={{ minWidth: 800 }} stickyHeader aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {[
                                        "Order Number", "Created At", "Advisor Name", "Mobile Number",
                                        "Farmer Alt. Number", "Farmer Name", "Village", "Taluka", 
                                        "District", "Pincode", "Nearby Location", "Product Names", 
                                        "Total Quantity", "Final Amount", "Order Status", "Change Status"
                                    ].map(header => (
                                        <TableCell key={header} sx={{ backgroundColor: "#BBCD79", fontWeight: "bold", textAlign: "center" }}>
                                            {header}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedDateOrders.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={16} align="center">
                                            No {filterStatus || 'orders'} found
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    selectedDateOrders.map(order => (
                                        <TableRow key={order._id} hover>
                                            <TableCell>{order._id}</TableCell>
                                            <TableCell>{new Date(order.createdAt).toLocaleString()}</TableCell>
                                            <TableCell>{order.advisorId?.name || 'N/A'}</TableCell>
                                            <TableCell>{order.customerId?.mobileNumber || 'N/A'}</TableCell>
                                            <TableCell>{order.customerId?.alternativeNumber || 'N/A'}</TableCell>
                                            <TableCell>{order.customerId?.name || 'N/A'}</TableCell>
                                            <TableCell>{order.customerId?.village || 'N/A'}</TableCell>
                                            <TableCell>{order.customerId?.taluka || 'N/A'}</TableCell>
                                            <TableCell>{order.customerId?.district || 'N/A'}</TableCell>
                                            <TableCell>{order.customerId?.pincode || 'N/A'}</TableCell>
                                            <TableCell>{order.customerId?.nearbyLocation || 'N/A'}</TableCell>
                                            <TableCell>
                                                <ul style={{ padding: 0, margin: 0 }}>
                                                    {order.products?.map(product => (
                                                        <li key={`${product.productId?._id}-${order._id}`}>
                                                            {product.productId?.name || 'N/A'}
                                                        </li>
                                                    )) || 'N/A'}
                                                </ul>
                                            </TableCell>
                                            <TableCell>{order.products?.reduce((acc, product) => acc + product.quantity, 0) || 0}</TableCell>
                                            <TableCell>{order.totalAmount || 0}</TableCell>
                                            <TableCell>{order.orderStatus}</TableCell>
                                            <TableCell>
                                                <Select
                                                    value={order.orderStatus || 'Pending'}
                                                    onChange={(e) => handleStatusChange(order._id, e.target.value)}
                                                    displayEmpty
                                                    fullWidth
                                                    disabled={updating}
                                                >
                                                    <MenuItem value="Pending">Pending</MenuItem>
                                                    <MenuItem value="Confirm">Confirm</MenuItem>
                                                    <MenuItem value="Cancel">Cancel</MenuItem>
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Box>
        </Sidebar>
    );
};

export default OperationalAdminSeeOrders;